import { useloggedUserInfo } from "../../stores/UserInfo";
import { storeToRefs } from "pinia";
import moment from 'moment';
import $ from "jquery";
import Vue from 'vue';
let mobile = false;
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    mobile = true;
}
let isReponsive = false;
if ($(window).width() <= 1024) {
    isReponsive = true;
}
String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined' ? args[number] : match;
    });
};

document.addEventListener("click", function (e) {
    var classlist = e.target.classList;
    if (!classlist.contains("action_icon")) {
        let actionBUtton = document.getElementsByClassName("action_icon");
        if (actionBUtton.length > 0) {
            for (let index = 0; index < actionBUtton.length; index++) {
                const x = actionBUtton[index];
                x.nextSibling.classList.remove("is-visible");
                x.nextSibling.style.opacity = 0;
                x.nextSibling.style.width = 0;
            }
        }

    }
});
LoadCommonFunction();
function LoadCommonFunction() {
    // $("body").on("click", '.expand-list-btn:not(.div_forShowTeam_avoid_conflict)', function (event) {
    //     event.stopImmediatePropagation();
    //     $(".left-menu-tab").toggleClass('left-menu-tab-collapsed');
    //     $(".right-content").toggleClass('right-content-expand');
    //     return false;
    // });

    // $('.v-expand').off().on('click', function () {
    //     $(".videof").toggleClass('videoexpand');
    //     $(".mapf").toggleClass('mapdeflex');

    // });

    // $('.bottom_filter_button').off().on('click', function () { //gaurav
    //     $(".card-panel").toggleClass("card-panel-extand");
    // });

    // $('.ancExpandAllCollapseAll').off().on('click', function () {
    //     $(".card-panel").toggleClass("card-panel-extand");
    // });


    $('.juda-btn').off().on('click', function (event) {
        $(this).toggleClass(' collapsed');

        if ($(this).hasClass("collapsed")) {

            $('#juda-menu').removeClass('show');
        } else {
            $('#juda-menu').addClass('show');
        }
        // if ($(this).hasClass("judamenuCE")) {
        //     // $.cookie("ContextMenu", "close", {
        //     //     expires: 10,         
        //     //     path: '/',        

        //     // });
        // }
        // else {
        //     $("#divjudamenuitems").removeClass("d-none");
        //     // $.cookie("ContextMenu", "open", {
        //     //     expires: 10,         
        //     //     path: '/',          

        //     // });
        // }
    });

    if ($(window).width() < 991) {
        $('#juda-menu').removeClass('show');
        $('#search-content').removeClass('show');
        $('.tabsearch').addClass('show');
    } else {
        if (typeof ($.cookie) != 'undefined' && $.cookie("ContextMenu") != "close") {
            $('#juda-menu').addClass('show');
        }
        $('#search-content').addClass('show');
    }
    $('a.juda-menu').off().on('click', function () {
        $(this).toggleClass('juda-closed');
    });
    $('.collapse-btns').off().on('click', function () {
        $(this).parent('span.collapse-head-btns').parent('div.theme-primary').toggleClass('p-actions-collapse');
        $(this).toggleClass('c-fa-angle-down');
    });
    // $('.ancExpandAllCollapseAll').off().on('click', function () {
    //     debugger;
    //     $(".left-menu-tab").find(".card(.open)").each(function () {
    //         $(this).find(".card-header a").toggleClass("collapsed");
    //         $(this).find(".collapse").addClass("hide");
    //         if ($(this).find(".collapse").hasClass("show")) {
    //             $(this).find(".collapse").switchClass("show", "hide", 500, "easeInOutQuad");
    //         }
    //         else {
    //             $(this).find(".collapse").switchClass("hide", "show", 500, "easeInOutQuad");
    //         }
    //     });
    // });

    $("body").on("keyup", ".searchcustomfilter", function (event) {
        event.stopImmediatePropagation();
        var val = $(this).val();
        $(this).closest(".form-group").find("input:checkbox,input:radio").each(function () {
            val = String(val).toLowerCase();
            var text = String($(this).closest(".custom-control").find(".custom-control-label").text()).toLowerCase();
            if (text.includes(val)) {
                $(this).closest("div.custom-control").show();
            }
            else {
                $(this).closest("div.custom-control").hide();
            }
        });
    });
    if (document.querySelector('.e-c-map') != null) {
        document.querySelector('.e-c-map').addEventListener('click', function (event) {
            event.stopImmediatePropagation();
            const icon = this.querySelector('i');
            const text = this.querySelector('span');

            if (icon.classList.contains('fa-angle-double-up')) {
                icon.classList.remove('fa-angle-double-up');
                icon.classList.add('fa-angle-double-down');
                text.innerHTML = 'Expand map';
            } else {
                icon.classList.remove('fa-angle-double-down');
                icon.classList.add('fa-angle-double-up');
                text.innerHTML = 'Collapse map';
            }
        });
    }
    if (document.querySelector('.e-c-details') != null) {
        document.querySelector('.e-c-details').addEventListener('click', function (event) {
            event.stopImmediatePropagation();
            const icon = this.querySelector('i');
            const text = this.querySelector('span');

            if (icon.classList.contains('fa-angle-double-up')) {
                icon.classList.remove('fa-angle-double-up');
                icon.classList.add('fa-angle-double-down');
                text.innerHTML = 'Expand details';
            } else {
                icon.classList.remove('fa-angle-double-down');
                icon.classList.add('fa-angle-double-up');
                text.innerHTML = 'Collapse details';
            }
        });
    }

}
$("body").on("click", '.expand-list-btn:not(.div_forShowTeam_avoid_conflict)', function (event) {
    event.stopImmediatePropagation();
    $(".left-menu-tab").toggleClass('left-menu-tab-collapsed');
    $(".right-content").toggleClass('right-content-expand');
    return false;
});
$("body").on("click", ".ancuserguide", function (event) {
    //if (typeof ($(this).attr("href")) == 'undefined') {
    event.stopImmediatePropagation();
    if ($(this).next("div.divancuserguide").attr("data-function")) {
        var fun = $(this).next("div.divancuserguide").attr("data-function");
        var fn = window[fun];
        fn();
    }
    else {
        $(this).next("div.divancuserguide").slideToggle();
    }
    //}
});
$("body").on('click', '.action_icon_cv', function (e) {
    e.stopImmediatePropagation();
    $(this).next('span').attr("style", "display:block");
    $(this).parent().find(".action-list-box").animate({
        width: "200px",
    }, 300);
});
$("body").on('click', '.close-action_cv', function (e) {
    $(this).parent().parent(".action-list-box").animate({
        width: "0px",
    }, 300);
});
var configCount = 0;
export default {

    data: function () {
        return {
            NoteListType: 'card',
            chkidCommonForVueCommon: 0,
            isDisabledCollapse: false
        }
    },
    setup() {
        const store = useloggedUserInfo();
        const { GetUserInfo, CLASSIC_EDITOR } = storeToRefs(store)
        if (GetUserInfo && GetUserInfo.value) {
            var filename = '';
            switch (GetUserInfo.value.UserType) {
                case "CA":
                    //   filename = '/content/Tourguide/CA/tourguide.js';
                    filename = '/Content/Tourguide/CA/tourguide.js';
                    break;
                case "NU":
                    //    filename = '/content/Tourguide/NU/tourguide.js';
                    filename = '/Content/Tourguide/NU/tourguide.js';
                    break;
                case "PM":
                    // filename = '/content/Tourguide/PM/tourguide.js';
                    filename = '/Content/Tourguide/CA/tourguide.js';
                    break;
            }
            if (configCount == 0) {

                var js = document.createElement('script');
                js.setAttribute("type", "text/javascript");
                js.setAttribute("src", filename);
                document.getElementsByTagName("head")[0].appendChild(js);
                configCount += 1;
            }
        }
        return { GetUserInfo, CLASSIC_EDITOR }
    },
    methods: {
        paramsToObject(entries) {
            const result = {}
            for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
                result[key] = value;
            }
            return result;
        },
        toggleCollapse: async function () {
            var vm = this;
            if (vm.isDisabledCollapse == true) {
                vm.isDisabledCollapse = false;
            } else {
                vm.isDisabledCollapse = true;
            }
        },
        ExpandAllCollapseAll: function () {
            var cardPanelElement = document.getElementsByClassName("card-panel");
            cardPanelElement.classList.toggle("card-panel-extand");
        },
        openShowNotePopup: function () {
            var ths = this;
            ths.isShowNotePopup = true;
            $("#tab-notes").trigger("click");
            ths.loadData("notes");

        },
        isCRMShowStausDrop: function (status, moduleName) {

            if (status == 'Draft' || status == 'Partially Received' || status == 'Approved' || status == 'Rejected' || status == 'Received' || status == 'Partially Approved' || status == 'Partially Rejected' || status == 'Approval Pending' || status == 'In Stock' || status == 'Cancelled' || (status == "Closed" && moduleName != "CRM_LEADS")) {
                return false;
            }
            return true;
        },
        removeLastChar: function (value, char) {
            var lastChar = value.slice(-1);
            if (lastChar == char) {
                value = value.slice(0, -1);
            }
            return value;
        },
        FilterDDLandNormalValue: function (item) {
            if (item && item.length > 0) {
                try {
                    return JSON.parse(item)[0].name;
                } catch (ex) { return item }
            } else {
                return item;
            }
        },
        FilterDDLValueForID: function (item) {
            if (item && item.length > 0) {
                try {
                    return JSON.parse(item)[0].value;
                } catch (ex) { return '' }
            } else {
                return ''
            }
        },
        openCRMAttachmentModal: function () {
            if ((typeof this.checkForAdditionalPermission !== 'undefined') && !this.checkForAdditionalPermission("AddAttachment")) {
                alertR($t("additionalOwnerAuthorityWarning"), true, $t('Alert'));
            } else {
                this.showAttachmentModal = (this.showAttachmentModal) ? false : true;
            }
        },
        openCRMEmailModal: function (item) {
            var type = 'AddEmail';
            if (item != null) {
                type = 'UpdateEmail';
            }
            if ((typeof this.checkForAdditionalPermission !== 'undefined') && !this.checkForAdditionalPermission(type)) {
                alertR($t("additionalOwnerAuthorityWarning"), true, $t('Alert'));
            } else {
                this.sendEmailModal = (this.sendEmailModal) ? false : true;
                editid = item;
            }
        },
        ChangeCRMLeadOwnerCheck: function (event) {
            var varObj = this;
            $('.chkItems:checkbox:checked').not("[id^='chkAll']").each(function () {
                varObj.OwnerIds.push($(this).val());
            });

            if (this.OwnerIds.length > 0) {
                this.showLeadOwnerModal = (this.showLeadOwnerModal) ? false : true;
            }
            else {
                talygen.alert($t("Selectchangeleadowner"));
            }
        },
        openCRMExistingContactModel: function () {
            if ((typeof this.checkForAdditionalPermission !== 'undefined') && !this.checkForAdditionalPermission("AddContact")) {
                alertR($t("additionalOwnerAuthorityWarning"), true, $t('Alert'));
            } else {
                this.showExistingContactModal = (this.showExistingContactModal) ? false : true;
            }
        },
        openCRMContactModal: function (contactDetails) {
            var type = 'AddContact';
            if (contactDetails != null) {
                type = 'UpdateContact';
            }
            if ((typeof this.checkForAdditionalPermission !== 'undefined') && !this.checkForAdditionalPermission(type)) {
                alertR($t("additionalOwnerAuthorityWarning"), true, $t('Alert'));
            } else {
                this.iscontactDetails = contactDetails;
                this.showContactModal = (this.showContactModal) ? false : true;
            }
        },
        openCRMProductModel: function () {
            if ((typeof this.checkForAdditionalPermission !== 'undefined') && !this.checkForAdditionalPermission("AddProducts")) {
                alertR($t("additionalOwnerAuthorityWarning"), true, $t('Alert'));
            } else {
                this.showProductModal = (this.showProductModal) ?
                    false : true;
            }
        },
        IsObject: function (obj) {
            return obj !== undefined && obj !== null && obj.constructor == Object;
        },
        CallFunction: function (fun, params) {
            this[fun].apply(null, params.split('^'));
        },
        onEnter: function (e) {
            e.stopPropagation();
            e.preventDefault();
            e.returnValue = false
            this.input = e.target.value + '\n'
        },
        convertSecondsToHHMM: function (d) {
            d = Number(d);
            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var hDisplay = h > 0 ? (h < 10 ? "0" : "") + h + ":" : "00:";
            var mDisplay = m > 0 ? (m < 10 ? "0" : "") + m : "00";
            return hDisplay + mDisplay;
        },
        encodeHtmlCode: function (value) {
            if (!Array.isArray(value)) {
                value = escape(value);
            }
            return value;
        },
        decodeHtmlCode: function (value) {
            if (!Array.isArray(value)) {
                value = unescape(value);
            }
            return value;
        },

        convertIntValueInFormat: function (value) {
            try {
                value = parseInt(value, 10);
                value = value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
                return value;
            } catch (ex) {
                return value
            }
        },

        GetValidation: function (object) {
            var obj = {};
            if (object.is_required) {
                obj['required'] = true;
            }
            if (object.length > 0) {
                obj['max'] = object.length;
            }
            if (object.validation_type == "email") {
                obj['email'] = true;
            }
            // if (object.dt_code == "date") {
            //     // obj['date_format'] = String(Globalize.culture().calendar.patterns.d).toUpperCase();
            //     obj['format'] = 'M/D/YYYY';
            // }
            // if (object.dt_code == "datetime") {
            //     obj['format'] = String(Globalize.culture().calendar.patterns.d).toUpperCase() + " " + String(Globalize.culture().calendar.patterns.t).replace("t", "A").replace("t", "");
            // }
            if (object.dt_code == "decimal" || object.dt_code == "float") {
                obj['decimal'] = 2;
                if (object.name == "ORIGNAL_CONVERSION_RATE") {
                    obj['min_value'] = 0;
                }
                if (object.name == "estimated_hours" || object.name == "estimated_cost" || object.name == "base_rate") {
                    obj['min_value'] = 1;
                }
            }
            if (object.dt_code == "int" || object.dt_code == "bigint") {
                obj['numeric'] = true;
            }
            if (object.dt_code == "url") {
                obj['url'] = 'require_protocol';
            }
            if (object.regular_expression != null && object.regular_expression != "") {
                obj['regex'] = object.regular_expression;
            }
            if (object.name == "project_named" && object.value != "") {
                var regexp = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
                obj['regex'] = regexp;
            }
            return obj;
        },
        formatDate: function (value) {
            if (value) {
                return moment(String(value), "YYYY-MM-DD")
            }
        },
        formatDateTimeFunc: function (value) {
            if (value) {
                return moment(value).format(String(Globalize.culture().calendar.patterns.d).toUpperCase() + " " + String(Globalize.culture().calendar.patterns.t).replace("t", "A").replace("t", ""))
            }
        },
        formatUserDateToYYYYMMDD: function (value) {
            return moment(String(value), String(Globalize.culture().calendar.patterns.d).toUpperCase()).format("YYYY-MM-DD");
        },
        createString: function (array, key) {

            return array.map(function (obj) {
                return "'" + obj[key] + "'";
            }).join(',');


        },
        getStatusClass: function (status, columnName, module = '') {
            var returnClass = ''
            if (columnName == 'status_id' || columnName == 'status_name' || columnName == 'gin_status') {
                var CommonClss = 'btn';
                if (module == 'fsm') {
                    CommonClss = 'px-2';
                }
                switch (status) {
                    case 'Active': case 'Approved': case 'Open':
                        returnClass = CommonClss + ' bg-success text-center text-white d-block';
                        break;
                    case 'Inactive': case 'Closed': case 'Cancelled': case 'Rejected': case 'Closed and Locked':
                        returnClass = CommonClss + ' bg-danger text-center text-white d-block';
                        break;
                    case 'Internal':
                        returnClass = CommonClss + ' bg-primary text-center text-white d-block';
                        break;
                    case 'Private':
                        returnClass = CommonClss + ' bg-danger text-center text-white d-block';
                        break;
                    case 'Publish':
                        returnClass = CommonClss + ' bg-info text-center text-white d-block';
                        break;
                    case 'Publish on Carrier Portal and Employee Portal':
                        returnClass = CommonClss + ' bg-dark text-center text-white d-block';
                        break;
                    case 'Approval Pending': case 'Partially Approved':
                        returnClass = CommonClss + ' bg-info text-center text-white d-block';
                        break;
                    case 'Received': case 'Partially Received':
                        returnClass = CommonClss + ' bg-secondary text-white text-center d-block';
                        break;
                    case 'Draft': case 'Waiting': case 'On Hold':
                        returnClass = CommonClss + ' bg-warning text-center text-white d-block';
                        break;
                    case 'No Gin': case 'Reopen':
                        returnClass = CommonClss + ' bg-primary text-center text-white d-block';
                        break;
                    default:
                        returnClass = CommonClss + ' bg-secondary text-center text-white d-block';
                }
            }
            return returnClass;
        },
        setHeaderForSelectedTab: function () {
            var tabText = $(".left-menu-tab.div-click-event .nav.nav-pills li.nav-item a.active").text();
            var ActionName = $.trim($("#hdnActionName").val());
            var hdndealname = $.trim($("#hdndealname").val());
            if (ActionName != "") {
                tabText = ActionName + " - " + tabText;
            }
            else if (hdndealname != "") {
                tabText = hdndealname + " - " + tabText;
            }

            $(".right-content #span_ForSelectedTabName").text(tabText);

        },
        bindLeftTabEventForSetHeading: function () {
            $("body").on("click", ".left-menu-tab.div-click-event .nav.nav-pills li.nav-item a", function () {
                var tabText = $(this).text();
                $(".right-content #span_ForSelectedTabName").text(tabText);
            });
        },
        convertDecimalValueInFormat: function (item) {
            try {
                var rslt = parseFloat(this.isBlank(item) ? 0 : item).toFixed(2).toLocaleString('en');
                var num_parts = rslt.split(".");
                num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return num_parts.join(".");
            } catch (ex) {
                return item
            }
        },
        GetLocalizedValue: function (fieldName, displayName) {
            var vm = this;
            try {
                if (vm.isBlank(displayName)) {
                    var chk = vm.$t(fieldName);
                    if (chk || /^\s*$/.test(chk)) {
                        return chk;
                    }
                    chk = vm.$t(String(fieldName));
                    if (chk || /^\s*$/.test(chk)) {
                        return chk;
                    }
                    return fieldName;
                }
                else {
                    chk = vm.$t(displayName);
                    if (chk || /^\s*$/.test(chk)) {
                        return chk;
                    }
                    chk = vm.$t(String(displayName));
                    if (chk || /^\s*$/.test(chk)) {
                        return chk;
                    }
                    return displayName;
                }
            }
            catch (ex) { console.log(ex) }
        },
        GetFieldPlaceHolder: function (str, displayName, isfor) {
            var name = this.GetLocalizedValue(str, displayName);
            try {
                if (isfor == "select") {
                    name = this.$t('SelectField', { 0: name });
                }
                else {
                    if (displayName == 'PONumber') {
                        name = this.GetLocalizedValue(str, displayName);
                    } else {
                        name = this.$t('EnterField', { 0: name });
                    }

                }
                return name;
            }
            catch (ex) {
                return name;
            }
        },
        isBlank: function (str) {
            return (!str || /^\s*$/.test(str));
        },
        truncateText: function (str, len) {
            if (String(str).length > len) {
                return String(str).substring(0, len) + "...";
            }
            else {
                return str;
            }
        },
        showDependentItem: function (item, value, parentIndex, chilDIndex) {
            var setValue = 0;
            if (value == "Yes" || value == "True" || value == "1") {
                setValue = 1;
            }
            $.each(this.GroupBy, $.proxy(function (key, parentItem) {
                $.each(parentItem.LeadData, function (childKey, childItem) {
                    if (childItem.parent_id == item.custom_field_id && childItem.dependent_type == 'show') {
                        childItem.dependent_show_type = setValue;
                    }
                    else if ('graduation' == item.name) {
                        $.each(parentItem.LeadData, function (filedIndex1, fieldItem1) {
                            if (fieldItem1.name == 'graduation_stream') {
                                if (value == "2") {
                                    fieldItem1.dependent_show_type = 1;
                                    fieldItem1.is_required = true;
                                } else {
                                    fieldItem1.dependent_show_type = 0;
                                    fieldItem1.is_required = false;
                                }
                            }
                        });
                    } else if ('post_graduation' == item.name) {
                        $.each(parentItem.LeadData, function (filedIndex1, fieldItem1) {
                            if (fieldItem1.name == 'post_graduation_stream') {
                                if (value == "2") {
                                    fieldItem1.dependent_show_type = 1;
                                    fieldItem1.is_required = true;
                                } else {
                                    fieldItem1.dependent_show_type = 0;
                                    fieldItem1.is_required = false;
                                }
                            }
                        });
                    } else if ('doctorate' == item.name) {
                        $.each(parentItem.LeadData, function (filedIndex1, fieldItem1) {
                            if (fieldItem1.name == 'doctorate_stream') {
                                if (value == "2") {
                                    fieldItem1.dependent_show_type = 1;
                                    fieldItem1.is_required = true;
                                } else {
                                    fieldItem1.dependent_show_type = 0;
                                    fieldItem1.is_required = false;
                                }
                            }
                        });
                    }

                });
            }, this));
            $.each(this.GroupBy, $.proxy(function (key, parentItem) {
                $.each(parentItem.EventData, function (childKey, childItem) {
                    if (childItem.parent_id == item.custom_field_id && childItem.name == 'recurring_date' && childItem.dependent_type == 'show') {
                        if (value == '4' || value == '5') {
                            setValue = 1;
                            childItem.is_required = true;
                        } else {
                            setValue = 0;
                            childItem.is_required = false;
                            childItem.dependent_show_type = setValue;
                        }
                        childItem.dependent_show_type = setValue;
                    } else if (childItem.parent_id == item.custom_field_id && childItem.name == 'week_day' && childItem.dependent_type == 'show') {
                        if (value == '3') {
                            setValue = 1;
                            childItem.is_required = true;
                        } else {
                            setValue = 0;
                            childItem.is_required = false;
                            childItem.dependent_show_type = setValue;
                        }

                        childItem.dependent_show_type = setValue;
                    } else if (childItem.parent_id == item.custom_field_id && childItem.dependent_type == 'show' && childItem.name == 'event_recurring_id') {
                        childItem.dependent_show_type = setValue;
                        childItem.is_required = (setValue == 1) ? true : false;
                    } else if (childItem.parent_id == item.custom_field_id && childItem.dependent_type == 'show') {
                        childItem.dependent_show_type = (setValue == 1) ? true : false;
                    }
                });
            }, this));
            item.value = value;
        },
        MakeArray: function (value, type) {
            var array = [];
            var arr = String(value).split(',');
            if (type == "radio" || type == "checkbox") {
                if (arr.length > 0) {
                    for (let item of arr) {
                        if (item.split("|").length > 1) {
                            var person = { name: item.split("|")[0].trim(), value: item.split("|")[1].trim() }
                            array.push(person);
                        }
                        else {
                            var person = { name: item.trim(), value: item.trim() };
                            array.push(person);
                        }
                    }
                }
            }
            else {
                if (arr.length > 0) {
                    for (let item of arr) {
                        var person = { name: item.trim(), value: item.trim() };
                        array.push(person);
                    }
                }
            }
            return array;
        },
        MakeNormalArray: function (value) {
            if (value) {
                try {
                    return JSON.parse(value);
                }
                catch (ex) {
                    return value;
                }
            }
            else {
                value = [];
            }
        },
        MakeSelectArray: function (objItem) {
            debugger;
            var array = [];
            var arr = String(objItem.select_options).split(',');
            if (arr.length > 0 && objItem.picklist_options == 'Lookup' && objItem.form_field_visibility == "YES" && objItem.dt_code == "select") {

                var person = { name: arr[0], value: arr[1] };
                array.push(person);

            }
            return array
        },
        CheckResumeFileExtension: function (filename) {

            var extension = filename.replace(/^.*\./, '');
            if (extension === filename) {
                extension = '';
            }
            else {
                extension = extension.toLowerCase();
            }

            switch (extension) {
                case 'png':
                case 'jpeg':
                case 'jpg':
                case 'gif':
                case 'xlsx':
                case 'xls':
                case 'doc':
                case 'docx':
                case 'txt':
                case 'pdf':
                case 'csv':
                    return true;
                default:
                    return false;
            }
        },
        CheckImageResumeFileExtension: function (filename) {

            var extension = filename.replace(/^.*\./, '');
            if (extension === filename) {
                extension = '';
            }
            else {
                extension = extension.toLowerCase();
            }

            switch (extension) {
                case 'png':
                case 'jpeg':
                case 'jpg':
                case 'gif':
                case 'bmp':
                    return true;
                default:
                    return false;
            }
        },
        CheckCSVResumeFileExtension: function (filename) {

            var extension = filename.replace(/^.*\./, '');
            if (extension === filename) {
                extension = '';
            }
            else {
                extension = extension.toLowerCase();
            }
            //Check extension
            // switch (extension) {
            //     case 'csv':
            //         return true;
            //     default:
            //         return false;
            // }
            if (extension == 'csv') { return true; }
        },
        AttachmentDisallowedFileType: function (DisallowedFileType) {
            //debugger;
            var vm = this;
            let array = [];
            let acceptFileTypeList = [];
            if (!vm.isBlank(DisallowedFileType)) {
                array = DisallowedFileType.split(',');
                array.forEach(function (row) {
                    let acceptFileType = {};
                    acceptFileType.fileType = row.replace(/^.*\./, '');
                    if (acceptFileType.fileType == "doc" || acceptFileType.fileType == "docx") {
                        acceptFileType.iconClass = "fa-file-word-o";
                    }
                    else if (acceptFileType.fileType == "txt") {
                        acceptFileType.iconClass = "fa-file-text-o";
                    }
                    else if (acceptFileType.fileType == "jpg" || acceptFileType.fileType == "bmp" || acceptFileType.fileType == "gif" || acceptFileType.fileType == "jpeg" || acceptFileType.fileType == "png") {
                        acceptFileType.iconClass = "fa-file-image-o";
                    }
                    else if (acceptFileType.fileType == "mp4" ||  acceptFileType.fileType == "mov") {
                        acceptFileType.iconClass = "fa-file-video-o";
                    }
                    else if (acceptFileType.fileType == "mp3") {
                        acceptFileType.iconClass = "fa-file-audio-o";
                    }
                    else {
                        acceptFileType.iconClass = "fa-file-o";
                    }
                    acceptFileTypeList.push(acceptFileType);
                });
            }

            return acceptFileTypeList;
        },
        GetBase64String: function (evt, from) {
            var vm = this;
            var f = evt.target.files[0];
            var reader = new FileReader();
            if (typeof f == "undefined") {
                return false;
            }
            if (from == 'Gallery') {
                if (!this.CheckImageResumeFileExtension(f.name.toLowerCase())) {
                    ShowAlert("Valid  files are JPEG, PNG, GIF,BMP", "warning", true, "Alert");
                    reader.onload = (function (theFile) {
                        return function (e) {
                            var fileName = '';
                            fileName = '';
                            vm.attachement_mime = '';
                            vm.fileName = '';
                            vm.base64String = '';
                            vm.GalleryData.item_image = '';
                        };
                    })(f);
                    reader.readAsBinaryString(f);
                }
                else {
                    reader.onload = (function (theFile) {
                        return function (e) {
                            var binaryData = e.target.result;

                            var base64StringEncoded = window.btoa(binaryData);

                            var fileName = f.name;
                            var n = fileName.lastIndexOf(".");
                            var extensionName = fileName.substring(n + 1, fileName.length);
                            var attachemnetMime = '{"ContentType":"' + f.type + '","Size":"' + f.size + '","Extension":"' + extensionName + '"}';
                            fileName = f.name;
                            vm.attachement_mime = attachemnetMime;
                            vm.fileName = fileName;

                            vm.base64String = base64StringEncoded;

                        };
                    })(f);

                    reader.readAsBinaryString(f);
                }
            }
            else if (from == 'csv') {
                if (!this.CheckCSVResumeFileExtension(f.name.toLowerCase())) {
                    ShowAlert("Valid  files are csv.", "warning", true, "Alert");
                    reader.onload = (function (theFile) {
                        return function (e) {
                        };
                    })(f);
                    reader.readAsBinaryString(f);
                }
                else {
                    reader.onload = (function (theFile) {
                        return function (e) {
                            var rows = e.target.result.split("\n");
                            var checkNameOrEmail = rows[0].includes("Name,Email");
                            if (checkNameOrEmail) {
                                $.each(rows, function (index, value) {
                                    if (index > 0) {
                                        var nameArr = rows[index].split(',');
                                        var strEmail = nameArr[1];
                                        var strName = nameArr[0];
                                        var result = false;
                                        if (typeof strEmail != 'undefined' && strEmail != null && strEmail != '') {
                                            result = strEmail.includes("@");
                                        }
                                        if (result && strName != '') {
                                            var emailContactObj = {
                                                value: index,
                                                name: nameArr[1],
                                                first_value: nameArr[0]
                                            }

                                            vm.UPLOADCONTACTDDL.push(emailContactObj);
                                            vm.UPLOADCONTACTDDLALL.push(emailContactObj);
                                        }

                                    }
                                });
                            }
                            else {


                                ShowAlert("please upload valid file.you can download sample file.", "warning", true, "Alert");
                            }


                        };
                    })(f);

                    reader.readAsBinaryString(f);


                }
            }

            else if (from == 'WishList' || from == 'Event') {
                if (!this.CheckImageResumeFileExtension(f.name.toLowerCase())) {
                    ShowAlert("Valid  files are JPEG, PNG, GIF,BMP..", "warning", true, "Alert");
                    $("#item_image").val("");
                    reader.onload = (function (theFile) {
                        return function (e) {
                            var fileName = '';
                            fileName = '';
                            vm.attachement_mime = '';
                            vm.fileName = '';
                            vm.base64String = '';
                            vm.item_image = '';
                        };
                    })(f);
                    reader.readAsBinaryString(f);
                }
                else {
                    reader.onload = (function (theFile) {
                        return function (e) {
                            var binaryData = e.target.result;
                            var base64StringEncoded = window.btoa(binaryData);
                            var fileName = f.name;
                            var n = fileName.lastIndexOf(".");
                            var extensionName = fileName.substring(n + 1, fileName.length);
                            var attachemnetMime = '{"ContentType":"' + f.type + '","Size":"' + f.size + '","Extension":"' + extensionName + '"}';
                            fileName = f.name;
                            vm.attachement_mime = attachemnetMime;
                            vm.fileName = fileName;
                            vm.base64String = base64StringEncoded;
                        };
                    })(f);
                    reader.readAsBinaryString(f);
                }
            }
            else {
                if (!this.CheckResumeFileExtension(f.name.toLowerCase())) {
                    ShowAlert("Valid  files are JPEG, PNG, GIF,doc,docx,txt,pdf,xlsx,xls or csv.", "warning", true, "Alert");
                    reader.onload = (function (theFile) {
                        return function (e) {
                            var fileName = '';
                            fileName = '';
                            vm.attachement_mime = '';
                            vm.fileName = '';
                            vm.base64String = '';
                        };
                    })(f);
                    reader.readAsBinaryString(f);
                }
                else {
                    reader.onload = (function (theFile) {
                        return function (e) {
                            var binaryData = e.target.result;
                            var base64StringEncoded = window.btoa(binaryData);
                            var fileName = f.name;
                            var n = fileName.lastIndexOf(".");
                            var extensionName = fileName.substring(n + 1, fileName.length);
                            var attachemnetMime = '{"ContentType":"' + f.type + '","Size":"' + f.size + '","Extension":"' + extensionName + '"}';
                            fileName = f.name;
                            vm.attachement_mime = attachemnetMime;
                            vm.fileName = fileName;
                            vm.base64String = base64StringEncoded;
                        };
                    })(f);
                    reader.readAsBinaryString(f);
                }
            }
        },
        BindActionButtonEvent: function () {
            let actionBUtton = document.getElementsByClassName("action_icon");
            if (actionBUtton.length > 0) {
                for (let index = 0; index < actionBUtton.length; index++) {
                    const x = actionBUtton[index];
                    x.addEventListener("click", function (e) {
                        let actionBUttonClose = document.getElementsByClassName("action_icon");
                        for (let index = 0; index < actionBUttonClose.length; index++) {
                            const y = actionBUttonClose[index];
                            y.nextSibling.classList.remove("is-visible");
                            y.nextSibling.style.opacity = 0;
                            y.nextSibling.style.width = 0;
                        }
                        var checkText = x.nextSibling;
                        let id = null;
                        const elem = checkText
                        let pos = 0, width = 50;
                        clearInterval(id);
                        elem.classList.add("is-visible");
                        var elemCountOnclick = elem.getElementsByClassName("actions-onclick")
                        for (let index = 0; index < elemCountOnclick.length; index++) {
                            const element = elemCountOnclick[index];
                            width = width + element.clientWidth + 10;
                        }
                        id = setInterval(frame, 5);
                        function frame() {
                            if (pos > width) {
                                clearInterval(id);
                            } else {
                                pos = pos + 5;
                                elem.style.width = pos + "px";
                            }
                        }
                        elem.style.opacity = 1;
                    });
                    x.nextSibling.getElementsByClassName("close-action")[0].addEventListener("click", function (e) {
                        x.nextSibling.classList.remove("is-visible");
                        x.nextSibling.style.opacity = 0;
                        x.nextSibling.style.width = 0;
                    });
                }
            }
        },
        // ShowPopOver: function() {
        //     debugger;

        //     $('[data-toggle="popoveruserguide"]').popover({
        //         html: true,
        //         sanitize: false,
        //         content: function () {
        //             return $(this).attr("data-content");
        //         },
        //         placement: "top",
        //         template: '<div class="popover popover-custom"><div class="arrow"></div><h3 class="popover-header"></h3><div><a href="javascript:;" class="close" style="font-size:23px;" data-dismiss="alert">&times;</a></div><div class="popover-body px-2">' + $(this).attr("data-content") + '</div></div>',
        //         trigger: "focus"

        //     }).on("show.bs.popover", function (e) {

        //         $('[data-toggle="popoveruserguide"]').not(e.target).popover("hide");
        //     });
        // },
        RadioButtonBootstrap: function () {
            if ($("#hdnisResponsives").length > 0 && $("#hdnisResponsives").val() == "RN") {
                RadioButtonBootstraps();
            }
            else {
                var chkedInput = $('input:radio').not("input[name^=multiselect]").not(".dynamic");

                $("label.universalradio-custom-control-label:not(.dynamic)").remove();
                $("label.checkrd").remove();
                $("label.offrd").remove();
                $(chkedInput).each(function (index, element) {
                    var $this = $(element);
                    if ($this.closest(".multiselect-container").length > 0) {
                        return;
                    }
                    var $div = $("<div>").addClass("custom-control custom-radio custom-control-inline");
                    var id = "rdo_" + index;
                    if ($this.attr("data-change-id") == "no") {
                        id = $this.attr("id");
                    }

                    var labelName = $(this).attr("data-resource");
                    var $divLabel = $("<label>").addClass("custom-control-label").attr("for", id).html(labelName);

                    $this.attr("id", id);
                    $this.addClass("form-check-input custom-control-input");//.addClass(className);//.hide();
                    if ($this.closest("div.custom-radio").length == 0) {
                        $this.wrap($div);
                    }
                    $this.after($divLabel);
                });
            }
        },
        // CheckBoxBootstrap: function() {
        //     var chkedInput = $('input:checkbox').not("input[name^=multiselect]").not(".addonlist").not(".chkProjects").not(".multilevel,.multilevelall,.dynamic");
        //     $("label[for^='chkAll']").remove();
        //     $(chkedInput).parent().find("label.custom-control-label:not(.dynamic)").remove();
        //     $(chkedInput).each(function (index, element) {
        //         var $this = $(element);
        //         if ($(this).closest(".multiselect-container").length > 0) {
        //             return;
        //         }
        //         var id = "chk_" + index;
        //         if ($this.attr("data-change-id") == "no") {
        //             id = $this.attr("id");
        //         }
        //         var $div = $("<div>").addClass("custom-control custom-checkbox");
        //         var $divLabel = $("<label>").addClass("custom-control-label universal-custom-control-label pt-1").attr("for", id)
        //         if ($this.attr("data-resource-id") != undefined) {
        //             $divLabel.html($this.attr("data-resource-id"));
        //         }

        //         if ($(this).is("[id^='chkAll']")) {
        //             $divLabel.attr("for", "chkAll_" + index);
        //             $(this).attr("id", "chkAll_" + index);
        //             $(this).on("click", function (e) {
        //                 debugger;
        //                 e.stopImmediatePropagation();
        //                 var table = $(e.target).closest('table');

        //                 if ($(e.target).prop('checked')) {
        //                     if ($(table).attr("data-visible") == "1") {
        //                         $(table).find("tr:visible").find("input:checkbox").not(":disabled,.nochecked").prop("checked", true);
        //                         $(table).find("tr:visible").find("input:checkbox").not(":disabled,.nochecked").attr("checked", "checked");
        //                     }
        //                     else {
        //                         $("input:checkbox", table).not(":disabled,.nochecked").prop("checked", true);
        //                         $("input:checkbox", table).not(":disabled,.nochecked").attr("checked", "checked");
        //                     }

        //                     if ($("input:checkbox", table).not(e.target).length > 0) {

        //                         if ($(table).closest(".tablehead").find("#DeleteMultiple").length > 0) {
        //                             $(table).closest(".tablehead").find("#DeleteMultiple").removeClass("disabled").addClass("enable");
        //                         }
        //                         else if ($(table).closest(".tablehead").find("a.iconenable").length > 0) {
        //                             $(table).closest(".tablehead").find("a.iconenable").removeClass("disabled").addClass("enable");
        //                         }
        //                         else if ($(e.target).attr("data-parent") != "no") {

        //                             $("#DeleteMultiple").removeClass("disabled").addClass("enable");
        //                             $(".tablehead a.iconenable").removeClass("disabled").addClass("enable");

        //                         }
        //                     }
        //                 }
        //                 else {
        //                     if ($(table).attr("data-visible") == "1") {
        //                         $(table).find("tr:visible").find("input:checkbox").not(":disabled,.nochecked").prop("checked", false);
        //                         $(table).find("tr:visible").find("input:checkbox").not(":disabled,.nochecked").removeAttr("checked");
        //                     }
        //                     else {
        //                         $("input:checkbox", table).not(":disabled,.nochecked").prop("checked", false);
        //                         $("input:checkbox", table).not(":disabled,.nochecked").removeAttr("checked");
        //                     }

        //                     if ($(table).closest(".tablehead").find("#DeleteMultiple").length > 0) {
        //                         $(table).closest(".tablehead").find("#DeleteMultiple").addClass("disabled").removeClass("enable");
        //                     }
        //                     else if ($(table).closest(".tablehead").find("a.iconenable").length > 0) {
        //                         $(table).closest(".tablehead").find("a.iconenable").addClass("disabled").removeClass("enable");
        //                     }
        //                     else if ($(e.target).attr("data-parent") != "no") {

        //                         $("#DeleteMultiple").addClass("disabled").removeClass("enable");
        //                         $(".tablehead a.iconenable").addClass("disabled").removeClass("enable");

        //                     }
        //                 }
        //             });

        //         }
        //         else {
        //             $(this).attr("id", id);
        //         }
        //         $(this).addClass("custom-control-input").hide();
        //         if ($(this).closest("div.custom-control").length == 0) {
        //             $(this).wrap($div);
        //         }
        //         $(this).prev("input:checkbox").removeClass("chkItems");
        //         if ($(this).next("label.custom-control-label").length == 0) {
        //             $(this).after($divLabel);
        //         }
        //         else {
        //             $(this).next("label.custom-control-label").attr("for", id);
        //         }
        //     });
        //     // $("body").on("click", ".chkItems", function (e) {

        //     //     var totalcheckboxes = 0;
        //     //     var selectedCount = 0;

        //     //     var gettable = $(this).closest("table");
        //     //     totalcheckboxes = $(gettable).find("input.chkItems:not(:disabled)").not("[id^='chkAll']").length;
        //     //     selectedCount = $(gettable).find('input.chkItems:checked').not("[id^='chkAll']").length;

        //     //     if (parseInt(selectedCount, 10) == parseInt(totalcheckboxes, 10)) {
        //     //         $(gettable).find(':checkbox[id^="chkAll"]').not(":disabled").prop("checked", true);
        //     //         $(gettable).find(':checkbox[id^="chkAll"]').not(":disabled").attr("checked", "checked");
        //     //     }
        //     //     else {
        //     //         $(gettable).find(':checkbox[id^="chkAll"]').not(":disabled").prop("checked", false);
        //     //         $(gettable).find(':checkbox[id^="chkAll"]').not(":disabled").removeAttr("checked");
        //     //     }

        //     //     if ($(this).is(':checked')) {

        //     //         if (totalcheckboxes > 0 && $(e.target).attr("data-parent") != "no") {
        //     //             $("#DeleteMultiple").removeClass("disabled");
        //     //             $("#DeleteMul").removeClass("disabled");
        //     //             $(".tablehead a.iconenable").removeClass("disabled").addClass("enable");

        //     //         }
        //     //     }
        //     //     else {
        //     //         debugger;
        //     //         if (selectedCount == 0) {
        //     //             $("#DeleteMultiple").addClass("disabled");
        //     //             $("#DeleteMul").addClass("disabled");
        //     //             $(".tablehead a.iconenable").removeClass("enable").addClass("disabled");
        //     //         }
        //     //         else {
        //     //             $(".ActiveInactive").removeClass("disabled");
        //     //         }
        //     //     }
        //     //     if ($(e.target).is(':checked')) {
        //     //         $(e.target).not(":disabled").attr("checked", "checked");
        //     //     }
        //     //     else {
        //     //         $(e.target).not(":disabled").removeAttr("checked");
        //     //     }

        //     // });
        //     $("input:checkbox:not(:disabled)").not("[id^='chkAll']").not("input[name^=multiselect]").not(".addonlist").not(".chkItems").not(".chkProjects").not(".chkTasks").not(".multilevel,.dynamic").click(function (e) {
        //         if ($(e.target).is(':checked')) {
        //             $(e.target).not(":disabled").attr("checked", "checked");
        //         }
        //         else {
        //             $(e.target).not(":disabled").removeAttr("checked");
        //         }
        //     });
        // },
        SetBootstraptoolTip: function (placementAttr) {
            $("body").find(".tooltip.show").remove();

            var ctrl = $("body").find("a:not(.noToolTip)[title][data-toggle-tooltip!=tooltip]");
            if (placementAttr != null && typeof (placementAttr) != 'undefined' && placementAttr != '') {
                placementAttr = 'left';
            }
            else {
                placementAttr = "top";
            }

            if (!mobile && ($("#hdnisResponsive").length > 0 && $("#hdnisResponsive").val() == "R")) {
                $("body").find("table").find("a").attr("data-placement", placementAttr);

                ctrl.attr("data-toggle-tooltip", "tooltip").tooltip({ trigger: 'hover' });

                $("body").find("a.actionicons[data-toggle-tooltip=tooltip]").addClass("float-left");

                ctrl = $("body").find("a:not(.noToolTip)[title][data-toggle-tooltip=tooltip]");

                if ($(".send-save-btn").length > 0) {
                    ctrl = $(".send-save-btn");
                    ctrl.attr("data-toggle-tooltip", "tooltip").tooltip({ trigger: 'hover' });
                }
                if ($("li.selectall").length > 0) {
                    ctrl = $("li.selectall");
                    ctrl.attr("data-toggle-tooltip", "tooltip").tooltip({ trigger: 'hover' });
                }
                if ($("li.selectnon").length > 0) {
                    ctrl = $("li.selectnon");
                    ctrl.attr("data-toggle-tooltip", "tooltip").tooltip({ trigger: 'hover' });
                }
                if ($(".modal-footer button[title]").length > 0) {
                    ctrl = $(".modal-footer button[title]");
                    ctrl.attr("data-toggle-tooltip", "tooltip").tooltip({ trigger: 'hover' });
                }
                if ($("#divShowLocationAvailability li[title]").length > 0) {
                    ctrl = $("#divShowLocationAvailability li[title]");
                    ctrl.attr("data-toggle-tooltip", "tooltip").tooltip({ trigger: 'hover' });
                }
                $('[data-toggle-tooltip=tooltip]').tooltip()
            }

        },
        ApplyTableFunction: function (ctrl, isSlidingIcon) {
            this.SetBootstraptoolTip();
            this.ResizeColumn(ctrl);
        },
        ResponsiveDataTable: function (ctrl, isSlidingIcon, isTableResponsive) {
            var vm = this;
            vm.SetResponsiveDataTable(ctrl, isTableResponsive);
            setTimeout(function () { vm.ApplyTableFunction(ctrl, isSlidingIcon); }, 500);
        },
        ResizeColumn: function (ctrl) {
            var vm = this;
            $("table#" + ctrl + " th[id!='nonresize']").not("th.nonresize").each(function () {
                var text = $.trim($(this).text());
                text = text.replace(/[\W_]+/g, "");
                $(this).find("div.sizer").remove();
                if ($(this).find("div.sizer").length == 0) {
                    text = String(text).split(' ').join('_');
                    text = String(text).toUpperCase();
                    var id = "th-" + text;
                    id = vm.SetThId(id);

                    $(this).attr("id", id);
                    $(this).append("<div id='" + id + "-sizer' class='sizer'></div>")
                }
            });
            if (localStorage.getItem(ctrl) != null && localStorage.getItem(ctrl) != '' && typeof (localStorage.getItem(ctrl)) != 'undefined') {
                var jsonData = JSON.parse(localStorage.getItem(ctrl));
                $("table#" + ctrl + " th[id!='nonresize']").not("th.nonresize").each(function () {
                    var data = jsonData.data;
                    var $this = $(this);
                    var filterData = data.filter(function (item) {
                        return item.id === $this.attr("id");
                    });

                    if (filterData != null && filterData.length > 0) {
                        filterData = filterData[0];
                        $this.width(filterData.width);
                        $this.css("min-width", filterData.width).css({ "min-width": filterData.minWidth, "max-width": filterData.width });
                        if ((Number(filterData.minWidth) - 18) > 0) {
                            $this.find("span:first").css("min-width", Number(filterData.minWidth) - 18);
                        }
                        else {
                            $this.find("span:first").css("min-width", 5);
                        }
                    }
                });
            }
            else {
                this.SetTableWidth(ctrl);
            }
        },
        SetScrolbar: function (theme, id, className, type) {
            try {
                if (theme == '' || theme == 'undefined') {
                    theme = "dark-thick";
                }
                if (id && id != null && type != 'H') {
                    $("#" + id).mCustomScrollBar({
                        theme: theme,
                        mouseWheelPixels: 50
                    });
                }
                else if (className && className != null && type != 'H') {
                    $("." + className).mCustomScrollBar({
                        theme: theme,
                        mouseWheelPixels: 50
                    });
                }
                else if (type == 'H') {
                    $("#" + id).mCustomScrollBar({
                        axis: "x",
                        theme: theme,
                        mouseWheelPixels: 50
                    });
                }
                else {
                    $(".custom-scrollbar-js").mCustomScrollBar({
                        theme: theme,
                        mouseWheelPixels: 50
                    });
                }
            } catch (ex) { console.log(ex.msg); }
        },
        SetResponsiveDataTable: function (id, isTableResponsive) {
            var ctrl = $("#" + id);

            $("#" + id).find(".expandedtr-rowbase").remove();
            $("#" + id).find(".expandtr-rowbase").removeClass("expanded");

            this.SetTableWidth(id);
            //ctrl.width(totalwidth); 
            if (typeof (isTableResponsive) == 'undefined') {
                isTableResponsive = true;
            }
            var chkwidth = 0;
            if (ctrl.outerWidth(true) > $(window).width() && isReponsive && isTableResponsive) {
                if (ctrl.find(".no-record").length == 0) {
                    ctrl.find("tbody tr").find("td:first").addClass("expandtr-rowbase");
                    ctrl.find("tbody tr").find("td:last").removeClass("expandtr-rowbase");
                    ctrl.find(".action-list-box").addClass("expand");
                    ctrl.find(".list-actions").addClass("fsm-list");
                    ctrl.find("tbody tr").find("td:first").click(function (e) {
                        var $this = $(this);
                        if ($(e.target).hasClass("expandtr-rowbase")) {
                            $this.toggleClass("expanded", 1000);
                            if ($this.hasClass("expanded")) {
                                var $tr = $("<tr>").addClass("expandedtr-rowbase").css("display", "revert");
                                var $trtd = $("<td>").attr("colspan", $this.closest("tr").find("td:visible").length);
                                var $trtdUl = $("<ul>");
                                $this.closest("tr").find("td:not(.mobile-action)").each(function (tdIndex) {
                                    if ($(this).hasClass("responsive-hide")) {
                                        var $trtdUlli1 = $("<li>");
                                        var $trtdUlli1span = $("<span>").addClass("left-label").html(ctrl.find("th:eq(" + tdIndex + ")").text() + ":");
                                        var $trtdUlli2span = $("<span>").addClass("right-label text-wrap").html($(this).html());
                                        $trtdUlli1.append($trtdUlli1span).append($trtdUlli2span);
                                        $trtdUl.append($trtdUlli1);
                                    }
                                });
                                $trtd.append($trtdUl);
                                $tr.append($trtd);
                                $tr.insertAfter($this.closest("tr"));
                                var position = $this.closest("tr").next("tr.expandedtr-rowbase").find("li:last").position();
                                $this.closest("tr").find("span.mobileaction").css("top", position.top);
                                $('.ddlchangestatus').each(function () {
                                    var statusId = $(this).attr("data-status");
                                    $(this).val(statusId);
                                });
                            }
                            else {
                                $this.closest("tr").next(".expandedtr-rowbase").remove();
                                $this.closest("tr").find("span.mobileaction").css("top", '');
                            }
                        }
                    });
                }
                var leftMenuWidth = 0;
                if ($(window).width() >= 767) {
                    leftMenuWidth = $(".left-menu-tab:visible").width();
                    leftMenuWidth = leftMenuWidth + 50;
                }
                var checkWidth = $(window).width() - leftMenuWidth;
                var checkSelect = ctrl.closest(".right-content").prev(".left-menu-tab").find(".sort-mobile-view .divSortColumns").find('.filterscroll');
                if (checkSelect != null && typeof (checkSelect) != 'undefined' && checkSelect.length > 0) {
                    if (checkSelect.find(".mCSB_container").length > 0) {
                        checkSelect.find(".mCSB_container").empty();
                    }
                    else {
                        checkSelect.empty();
                    }

                    var sortDDl = ctrl.closest(".right-content").prev(".left-menu-tab").find(".sort-mobile-view .divColumnsOrderBy").find('.filterscroll');
                    sortDDl.find("input:radio[value='" + $("#hdnSortExp").val() + "']").prop("checked", true);
                    checkSelect.find("input:radio[value='" + $("#hdnSortBy").val() + "']").prop("checked", true);
                    checkSelect.find("input:radio").click(function () {
                        var val = $(this).val();
                        if (sortDDl.find("input:radio:checked").val() == "") {
                            sortDDl.find("input:radio[value='ASC']").prop("checked", true);
                        }
                        $("#hdnSortExp").val(sortDDl.find("input:radio:checked").val());
                        $("#hdnSortBy").val(val);
                    });
                }
                ctrl.find("th").each(function (index) {

                    chkwidth += parseInt($(this).outerWidth(true), 10);
                    var nextthWidth = 0;
                    if (((chkwidth + nextthWidth) >= checkWidth) && index > 0) {
                        $(this).addClass("responsive-hide").hide();
                        ctrl.find("tbody tr").each(function () {
                            if (!$(this).find("td:eq(" + index + ")").hasClass('mobile-action')) {
                                $(this).find("td:eq(" + index + ")").addClass("responsive-hide").hide();
                            }
                        });
                    }
                    if (checkSelect != null && typeof (checkSelect) != 'undefined' && checkSelect.length > 0 && $(this).find("span.sort:not(:visible)").length > 0) {
                        if (checkSelect.find(".mCSB_container").length > 0) {
                            checkSelect.find(".mCSB_container").append('<div class="custom-control custom-radio custom-control-inline">' +
                                ' <input type="radio" class="custom-control-input dynamic" id="chksort' + $(this).find("span.sort").attr("id") + '" name="rdoSortColumns" value="' + $(this).find("span.sort").attr("id") + '">' +
                                ' <label class="custom-control-label" for="chksort' + $(this).find("span.sort").attr("id") + '">' + $(this).find("span.sort").text() + '</label>' +
                                '</div>');
                        }
                        else {
                            checkSelect.append('<div class="custom-control custom-radio custom-control-inline">' +
                                ' <input type="radio" class="custom-control-input dynamic" id="chksort' + $(this).find("span.sort").attr("id") + '" name="rdoSortColumns" value="' + $(this).find("span.sort").attr("id") + '">' +
                                ' <label class="custom-control-label" for="chksort' + $(this).find("span.sort").attr("id") + '">' + $(this).find("span.sort").text() + '</label>' +
                                '</div>');
                        }
                    }
                });

                ctrl.find("th:visible:last").not('.responsive-hide').addClass("w-100");
                ctrl.find("td.mobile-action").addClass("mobile-view");
                ctrl.removeAttr("style");
                if (ctrl.find(".no-record").length > 0) {
                    ctrl.find(".custom-control").addClass("pl-2");
                }
            }
        },
        SetTableWidth: function (id) {
            $("table#" + id).find("th:not('.widthnotset')").each(function () {
                var text = $.trim($(this).text());

                text = String(text).replace(/ /g, "_");
                var span = $.trim(String(text));
                var width = String(span).length * 12;
                //width += 20;
                if (width < 85) {
                    width = 85;
                }
                var newWidth = width;
                if (typeof ($(this).attr("data-width")) != 'undefined' && $(this).attr("data-width") != "") {
                    newWidth = $(this).attr("data-width");
                }
                $(this).find(":checkbox[id^='chkAll']").closest("th").css("width", "40px").attr("id", "nonresize");
                if ($(this).attr("id") == "nonresize") {
                    //newWidth = 40;
                    $(this).width(newWidth).css({ "width": "3%", "min-width": newWidth, "max-width": newWidth });
                }
                else {
                    if (String(newWidth).includes("%")) {
                        $(this).width(newWidth).css({ "width": newWidth, "min-width": width, "max-width": width });
                    }
                    else {
                        $(this).width(newWidth).css({ "width": newWidth, "min-width": newWidth, "max-width": newWidth });
                    }
                }

                width = Number(newWidth) - 18;
                $(this).find("span:first").css("min-width", width);
            });
        },
        SetThId: function (ctrl) {
            var vm = this;
            if ($("#" + ctrl).length > 0) {
                vm.chkidCommonForVueCommon += 1;
                if (String(ctrl).indexOf("_") > 0) {
                    ctrl = String(ctrl).split('_')[0];
                }
                ctrl = ctrl + "_" + vm.chkid;
                return this.SetThId(ctrl);
            }
            else {
                return ctrl;
            }
        },
        confirmR: function (message, showtitle, title, okAction, cancelAction) {
            if (showtitle) {
                talygen.confirm({
                    title: title,
                    message: message,
                    callback: function (result) {
                        if (result) {
                            okAction();
                        }
                        else {
                            if (cancelAction != undefined)
                                cancelAction();
                        }
                    }
                });
            }
            else {
                talygen.confirm(message, function (result) {
                    if (result) {
                        okAction();
                    }
                });
            }
        },

        SetResponsiveTab: function () {
            if ($(window).width() <= 767) {
                $(".responivecustomtab li").each(function () {
                    var ancCtrl = $(this).find("a");
                    var tabid = ancCtrl.attr("href");

                    var ancId = ancCtrl.attr("id");
                    if ($(tabid).prev("a#" + ancId).length == 0 && $(tabid).length > 0) {

                        var anc = $("<a>").addClass("card mobile-tab-accordian").attr({ "href": "javascript:;", "id": ancId });
                        var ancDiv = $("<div>").addClass("card-header").attr("role", "tab");
                        var ancDivh5 = $("<h5>");
                        var ancDivh5Span = $("<span>").addClass("mb-0");
                        ancDivh5Span.append(ancCtrl.html());
                        ancDivh5Span.append('<em class="fa fa-angle-down rotate-icon float-right" aria-hidden="true"></em>');
                        ancDivh5.append(ancDivh5Span);
                        ancDiv.append(ancDivh5);
                        anc.append(ancDiv);
                        anc.click(function () {
                            $(".tab-pane").removeClass("active show");
                            $(tabid).addClass("active show");
                        });
                        anc.insertBefore($(tabid));
                    }
                });
            }
        },
        getMinDate: function () {
            var d = new Date();
            d.setDate(d.getDate() - 1);
            return d;
        },
        ShowAlert: function (message, type, showtitle, title, isHide) {
            if (!type || type == null || type == "" || typeof (type) == "undefined") {
                type = "success";
            }

            $("div#notifymessage").show().find(".msg").removeClass("alert-success").removeClass("alert-danger").removeClass("alert-warning");
            var isHeader = false;
            if ($("div#notifymessage").find(".hdr").length > 0) {
                isHeader = true;
                $("div#notifymessage").find(".hdr").removeClass("alert-success-header").removeClass("alert-danger-header").removeClass("alert-warning-header");
            }
            if (showtitle && isHeader) {
                $("div#notifymessage").find(".hdr h4").html(title);
                $("div#notifymessage").find(".hdr h4").hide();
            }
            if (type.toLowerCase() == "success") {
                if (isHeader) {
                    $("div#notifymessage").find(".hdr").addClass("alert-success-header");
                }
                $("div#notifymessage").find(".msg").addClass("alert-success").find("span:first").html(message);
            }
            else if (type.toLowerCase() == "warning") {
                if (isHeader) {
                    $("div#notifymessage").find(".hdr").addClass("alert-warning-header");
                }
                $("div#notifymessage").find(".msg").addClass("alert-warning").find("span:first").html(message);
            }
            else {
                if (isHeader) {
                    $("div#notifymessage").find(".hdr").addClass("alert-danger-header");
                }
                $("div#notifymessage").find(".msg").addClass("alert-danger").find("span:first").html(message);
            }
            if (isHide || type == "success") {
                setTimeout(function () {
                    $('div#notifymessage').hide();
                }, 3000);
            }
            $("body").on("click", "#closenotifymessage", function () {
                $("#notifymessage").hide().find(".msg span:first").html('');
            });
        },
        JSONToCSVConvertor: function (JSONData, fileName, ShowLabel) {
            debugger
            var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
            var CSV = '<table border="1" cellpadding="10" cellspacing="10"  aria-describedby="vuecommon">><tr>';
            if (ShowLabel) {
                var row = "";
                for (var index in arrData.schema) {
                    if (arrData.schema[index].VISIBLE) {
                        var displayName = arrData.schema[index].DISPLAY_NAME;
                        var fieldName = arrData.schema[index].COLUMN_NAME;
                        if (!(displayName || /^\s*$/.test(displayName))) {
                          var chk = String(fieldName)
                            if (chk || /^\s*$/.test(chk)) {
                                displayName = chk;
                            }
                            else {

                                chk = String(fieldName);
                                if (chk || /^\s*$/.test(chk)) {
                                    displayName = chk;
                                } else {
                                    displayName = fieldName;
                                }
                            }
                        }
                        else {
                           
                            var chk = String(displayName);
                            if (chk || /^\s*$/.test(chk)) {
                                displayName = chk;
                            } else {                           
                                chk = String(displayName);
                                if (chk || /^\s*$/.test(chk)) {
                                    displayName = chk;
                                }
                            }
                        }
                        row += ' <th scope="col">' + displayName + '</th>';
                        if (fieldName == "planned_start_date" && $("#ControllerName").val() == "project") {
                            displayName = "PlanEndDate";
                          
                            var chk = String(displayName);
                            if (chk || /^\s*$/.test(chk)) {
                                displayName = chk;
                            } else {

                                chk = String(displayName);
                                if (chk || /^\s*$/.test(chk)) {
                                    displayName = chk;
                                }
                            }
                            row += ' <th scope="col">' + displayName + '</th>';
                        }
                    }
                }
                if (fileName == "Lead-Detail") {

                    row = row + ' <th scope="col">' + String("LastNote") + '</th>';
                }
                CSV += row + '</tr>';
            }
            for (var i = 0; i < arrData.data.length; i++) {
                var row = "<tr>";
                var j = 0;

                for (var j = 0; j < arrData.schema.length; j++) {
                    if (arrData.schema[j].VISIBLE) {
                        var arrValue = ''
                        if (arrData.schema[j].DATA_TYPE == 'date') {
                            debugger
                            arrValue = arrData.data[i][arrData.schema[j].COLUMN_NAME] == null ? "" : moment(String(arrData.data[i][arrData.schema[j].COLUMN_NAME]), "YYYY-MM-DD").format("DD/MM/YYYY");

                        } else {
                            arrValue = arrData.data[i][arrData.schema[j].COLUMN_NAME] == null ? "" : arrData.data[i][arrData.schema[j].COLUMN_NAME];
                        }
                        row += '<td type="' + arrData.schema[j].DATA_TYPE + '">' + arrValue + '</td>';
                        if (arrData.schema[j].DATA_TYPE == 'date' && arrData.schema[j].COLUMN_NAME == "planned_start_date" && $("#ControllerName").val() == "project") {
                            arrValue = arrData.data[i]['planned_end_date'] == null ? "" : moment(String(arrData.data[i]['planned_end_date']), "YYYY-MM-DD").format("DD/MM/YYYY");
                            row += '<td type="' + arrData.schema[j].DATA_TYPE + '">' + arrValue + '</td>';
                        }
                    }
                }
                if (fileName == "Lead-Detail") {
                    var noteHtmlData = (arrData.data[i]["lead_note"] == null) ? "" : arrData.data[i]["lead_note"];
                    noteHtmlData = noteHtmlData.replace(/<div>/ig, "");
                    noteHtmlData = noteHtmlData.replace(/<\/div>/ig, '<br style="mso-data-placement:same-cell;" />');
                    var el = document.implementation.createHTMLDocument().createElement('div');
                    el.innerHTML = noteHtmlData;
                    noteHtmlData = el.innerHTML;
                    row = row + '<td type="html" data-cell-type="html" markup="html">' + noteHtmlData + '</td>';
                }
                //row= row.slice(0, row.length - 1);
                CSV += row + '</tr>';
            }
            CSV += "</table>";
            if (CSV == '') {
                growl.error("Invalid data");
                return;
            }
            var fileName = fileName;
            console.log(CSV)
            var uri = 'data:application/xls;charset=utf-8,' + escape(CSV);
            var link = document.createElement("a");
            link.href = uri;
            link.style = "visibility:hidden";
            link.download = fileName + ".xls";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        JSONToCSVConvertorData: function (JSONData, fileName, ShowLabel) {
            var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
            var CSV = '';
            if (arrData.data && arrData.data.length > 0) {
                // Include bold headers as regular data
                if (ShowLabel) {
                    var boldHeaderRow = '';
                    for (var key in arrData.data[0]) {
                        // Skip 'service_type_id', 'is_publish', and 'total_records' columns
                        if (key !== 'service_type_id' && key !== 'is_publish' && key !== 'TOTALRECORDS') {
                            boldHeaderRow += key.toUpperCase().replace(/_/g, ' ') + ',';
                        }
                    }
                    CSV += boldHeaderRow.slice(0, -1) + '\r\n';
                }
                // Construct CSV data rows
                for (var i = 0; i < arrData.data.length; i++) {
                    var row = '';
                    for (var key in arrData.data[i]) {
                        // Skip 'service_type_id', 'is_publish', and 'total_records' columns
                        if (key !== 'service_type_id' && key !== 'is_publish' && key !== 'TOTALRECORDS') {
                            var value = arrData.data[i][key];
                            // Format 'created_at' date properly
                            if (key === 'created_at' && value) {
                                value = new Date(value).toLocaleDateString('en-US');
                            }
                            // Convert status_id to 'Active' or 'Inactive'
                            if (key === 'status_id') {
                                value = value === 1001 ? 'Active' : 'Inactive';
                            }
                            row += '"' + value + '",';
                        }
                    }
                    CSV += row.slice(0, -1) + '\r\n';
                }
        
                if (CSV == '') {
                    growl.error("Invalid data");
                    return;
                }
        
                var uri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(CSV);
                var link = document.createElement("a");
                link.href = uri;
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                growl.error("No data to export");
            }
        },
        
        
        GetFormSchemaFieldName: function (name, display_name) {
            if (display_name) {
                return this.$t(display_name);
            } else {
                return this.$t(name);
            }
        },
        getSAStatusClassList: function (status) {
            var returnClass = '';
            switch (status) {
                case 'Completed':
                case 'Dispatched':
                    returnClass = 'px-2 bg-success text-white text-center d-block';
                    break;
                case 'Cancelled':
                case 'Canceled':
                    returnClass = 'px-2 bg-danger text-white text-center d-block';
                    break;
                case 'Cannot Complete':
                case 'Open':
                case 'Unscheduled':
                    returnClass = 'px-2 bg-secondary text-white text-center d-block';
                    break;
                case 'In Progress':
                    returnClass = 'px-2 bg-info text-white text-center d-block';
                    break;
                case 'Needs Reschedule':
                case 'Scheduled':
                    returnClass = 'px-2 bg-dark text-white text-center d-block';
                    break;
                case 'Failed':
                case 'On Hold':
                case 'Need to Reschedule':
                    returnClass = 'px-2 bg-warning text-white text-center d-block';
                    break;
                default:
                    returnClass = '';
            }
            return returnClass;
        },
        GetFieldDisplayName: function (name, display_name) {
            if (display_name) {
                return this.$t(display_name);
            } else {
                return this.$t(name);
            }
        },
        TruncateStr(str, len) {
            var convertstring = '' + str;
            if (convertstring != null)
                if (convertstring.length <= len) return str;
                else return convertstring.substring(0, len) + "...";
        },
        setTourGuideSteps(val, context) {
            var vm = this;
            var hasRouteParam = vm.$route.matched[0].path.indexOf(':') > -1;
            var current_Url = hasRouteParam ? vm.$route.matched[0].path.substring(1, vm.$route.matched[0].path.indexOf(':') - 1) : window.location.pathname.substring(1);
            var tourGuidesteps = JSON.stringify(tourData[0][current_Url]);
            tourGuidesteps = typeof (tourGuidesteps) == 'undefined' ? '' : JSON.parse(tourGuidesteps);
            if (tourGuidesteps == "")
                return
            tourGuidesteps.items.forEach(item => {
                item.title = vm.$t(item.title);
                item.content = vm.$t(item.content);
            })
            console.log(tourGuidesteps);
            var tourguide = new Tourguide({
                steps: tourGuidesteps.items,
                onStart: function (options) {
                    console.log('onStart');
                },
                onStop: function (options) {
                    console.log('onstop');
                    switch (current_Url) {
                        case "CRM/Accounts":
                            context.showAccountPopup = false;
                            break;
                        case "Brand/BrandListing":
                            context.showAddEditModal = false;
                            break;
                    }
                },
                onComplete: function () {
                    $("div#manageProjectModal").find("button.close").trigger('click');
                },
                onStep: function (currentstep, type) {
                    debugger
                    switch (current_Url) {
                        case "Ticketing/Index":
                            console.log(currentstep.index);
                            switch (currentstep.index) {
                                case 1:
                                    $(".actions mobileaction").trigger('click');
                                    break;
                                case 2:
                                    $(".actions mobileaction").trigger('click');
                                    //$("#DivTimerNew").modal('hide');
                                    break;
                                case 3:
                                    $("a#btnTimer").trigger('click');
                                    break;
                                case 4:
                                    $("#closeTimer").trigger('click');
                                    break;
                                case 6:
                                    $("#anceditMyDashboard").trigger('click');
                                    break;
                                case 7:
                                    $("#anceditMyDashboard").addClass('d-none');
                                    $("#ancCancelMyDashboard").removeClass('d-none');
                                    $("#ancSaveMyDashboard").removeClass('d-none');
                                    $("#ancAddGroupMyDashboard").removeClass('d-none');
                                    break;
                                case 5:
                                case 8:
                                    $(".leftsideicon").removeClass('w-75').addClass('w-100');
                                    $(".rightsideicon").addClass('d-none').addClass('w-25');
                                    //$(".shortcuts-menu").sortable("disable");
                                    //$(".connectedSortable").sortable("disable");
                                    $(".shortcuts-menu a").removeAttr('onmousedown');
                                    $(".connectedSortable a").removeAttr('onmousedown');
                                    $(".spngropuname").removeAttr('style');
                                    $(".txtgropuname").css('display', 'none');
                                    $("#anceditMyDashboard").removeClass('d-none').removeAttr('style');
                                    $("#ancCancelMyDashboard").addClass('d-none');
                                    $("#ancSaveMyDashboard").addClass('d-none');
                                    $("#ancAddGroupMyDashboard").addClass('d-none');
                                    break;
                            }
                            break;
                        case "CRM/Accounts":
                            switch (currentstep.index) {
                                case 3:
                                    context.showAccountPopup = false;
                                    break;
                                case 4:
                                    tourguide.stop();
                                    context.showAccountPopup = true;
                                    setTimeout(function () {
                                        tourguide.start(currentstep.index - 1);
                                    }, 1000);
                                    break;
                            }
                            break;
                        case "Brand/BrandListing":
                            switch (currentstep.index) {
                                case 1:
                                    context.showAddEditModal = false;
                                    break;
                                case 2:
                                    tourguide.stop();
                                    context.showAddEditModal = true;
                                    setTimeout(function () {
                                        tourguide.start(currentstep.index - 1);
                                    }, 1000);

                                    break;
                                case 3:
                                    context.showAddEditModal = false;
                                    break;
                            }
                            break;
                        case "CRM/StageListing":
                            if (currentstep.index == 4) {
                                tourguide.stop();
                                $('.action_icon').first().trigger('click');
                                setTimeout(function () {
                                    tourguide.start(currentstep.index - 1);
                                }, 1000);
                            }
                            break;
                        case "CRM/ContractListing":
                            if (currentstep.index == 3) {
                                tourguide.stop();
                                $('.action_icon').first().trigger('click');
                                setTimeout(function () {
                                    tourguide.start(currentstep.index - 1);
                                }, 1000);
                            }
                            break;
                        case "CRM/ItemListing":
                            if (currentstep.index == 4) {
                                tourguide.stop();
                                $('.action_icon').first().trigger('click');
                                setTimeout(function () {
                                    tourguide.start(currentstep.index - 1);
                                }, 1000);
                            }
                            break;
                    }
                },
                onAction: function (currentstep, e) {
                }
            });

            tourguide.start(val);
        },
        // Function to convert JSON data to CSV and trigger download
        JSONToCSVConvertorData: async function (JSONData, ReportTitle, ShowLabel) {
            var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
            var CSV = '';
            if (ShowLabel) {
                var row = '';
                for (var index in arrData[0]) {
                    row += index + ',';
                }
                row = row.slice(0, -1);
                CSV += row + '\r\n';
            }
            for (var i = 0; i < arrData.length; i++) {
                var row = '';
                for (var index in arrData[i]) {
                    row += '"' + arrData[i][index] + '",';
                }
                row.slice(0, row.length - 1);
                CSV += row + '\r\n';
            }
            if (CSV == '') {
                alert('Invalid data');
                return;
            }
            var fileName = ReportTitle + '_';
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
            var link = document.createElement('a');
            link.href = uri;
            link.style = 'visibility:hidden';
            link.download = fileName + new Date().getTime() + '.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        //  JSONToCSVConvertor(JSONData, fileName, ShowLabel) {
        //     //debugger;
        //     var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
        //     var CSV = '';
        //     if (ShowLabel) {
        //         var row = "";
        //         for (var index in arrData.schema) {
        //             row += arrData.schema[index].COLUMN_NAME + ',';
        //         }
        //         row = row.slice(0, -1);
        //         console.log("excel data " + JSON.stringify(row))
        //         CSV += row + '\r\n';
        //     }
        //     //debugger;
        //     for (var i = 0; i < arrData.data.length; i++) {
        //         var row = "";
        //         var j = 0;
        //         for (var index in arrData.data[i]) {
        //             //var arrValue = arrData.data[i][index] == null ? "" : '="' + arrData.data[i][index] + '"';
        //             if (j < (arrData.schema.length)) {
        //                 var arrValue = arrData.data[i][arrData.schema[j].COLUMN_NAME] == null ? "" : '"' + arrData.data[i][arrData.schema[j].COLUMN_NAME] + '"';
        //                 //row += arrValue + ',';
        //                 if (JSON.stringify(arrData.schema[j].COLUMN_NAME) == '"created_at"' || JSON.stringify(arrData.schema[j].COLUMN_NAME) == '"StartDate"')
        //                 {
        //                     var dataofcreated_at = JSON.stringify(arrValue.replace(',', '-'));
        //                     var datewithouttimeofcreated_at = dataofcreated_at.substr(0, dataofcreated_at.indexOf('T'));
        //                     var datewithtime = dataofcreated_at.substr(dataofcreated_at.indexOf('T') + 1).replace(/"/g, '');
        //                     datewithtime = datewithtime.substr(0, datewithtime.indexOf('\\'));
        //                     var alldate = datewithouttimeofcreated_at.split('-');
        //                     var yearofcreatedat = alldate[0].replace(/[^\w\s]/gi, '');
        //                     var monthofcreated_at = alldate[1].replace(/[^\w\s]/gi, '');
        //                     var dayofcreated_at = alldate[2].replace(/[^\w\s]/gi, '');
        //                     var returndate = monthofcreated_at + "-" + dayofcreated_at + "-" + yearofcreatedat + "T" + datewithtime;
        //                     row += returndate + ',';
        //                 }
        //                 else
        //                 {
        //                     row += arrValue.replace(',', '-') + ',';
        //                 }
        //                 j++;
        //             }
        //         }
        //         row.slice(0, row.length - 1);
        //         CSV += row + '\r\n';
        //     }
        //     if (CSV == '') {
        //         growl.error("Invalid data");
        //         return;
        //     }
        //     var fileName = fileName;
        //     var uri = 'data:application/csv;charset=utf-8,' + escape(CSV);
        //     var link = document.createElement("a");
        //     link.href = uri;
        //     link.style = "visibility:hidden";
        //     link.download = fileName + ".csv";
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        // },
    }
}

