import Vue from 'vue'
import './set-public-path'
import App from './App.vue'
import VueI18n from "vue-i18n";
import router from "./router";
import EN from "./localization/en";
import breadcrumb from './components/Common/BreadCrumb.vue'
import ModuleHeader from '../src/components/Header/ModuleHeader.vue';
import ListingStatus from './components/Common/ListingStatus.vue';	
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Loader from './components/Common/Loader.vue'
import {TgList} from 'tg-listv/dist/tglistv.common'
import { DynamicForm } from 'tg-controls_cli/dist/myLib.common'
//import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Multiselect from 'vue-multiselect'
import commonMixin from '../src/assets/common/vue-common.js'
import ListingcommonMixin from '../src/assets/common/vue-commonList.js'
import { createPinia, PiniaVuePlugin } from 'pinia';
import { useloggedUserInfo } from '../src/stores/UserInfo';
import singleSpaVue from 'single-spa-vue';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import Vue2ColorPicker from 'vue2-color-picker';
import moment from 'moment';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import vuescroll from 'vuescroll';
 var $ = require('jquery');
import draggable from "vuedraggable";
import Popover from 'vue-js-popover'
import VoerroTagsInput from '@voerro/vue-tagsinput';
import { registerLicense } from '@syncfusion/ej2-base';
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.config.productionTip = false;
Vue.prototype.$= $;
require('./vee-validate');
Vue.use(CKEditor);
 Vue.use(VueI18n);
 Vue.use(PiniaVuePlugin);
 Vue.use(Popover,{ tooltip: true })
 Vue.component('listing-status', ListingStatus);
 Vue.component('draggable',draggable);
//  Vue.component('v-date-picker', DatePicker)
 Vue.component('Multiselect', Multiselect);
 Vue.component('breadcrumb', breadcrumb);
 Vue.component('loader', Loader)
 Vue.component('ModuleHeader',ModuleHeader);
 Vue.component('tg-list',TgList);
 Vue.component('dynamic-form',DynamicForm);
 Vue.component('v-date-picker', DatePicker)
 registerLicense('ORg4AjUWIQA/Gnt2VlhiQlVPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSH9RdkVqWn5dcHBRRGQ=');
 Vue.use(VueTelInput);
 Vue.use(Vue2ColorPicker);
 Vue.use(vuescroll);
 Vue.component('tags-input', VoerroTagsInput);
 const pinia=createPinia();
 window.pinia = pinia;
 Vue.use(pinia);
const i18n = new VueI18n({
  locale: "en",
  messages: {
    en:EN,
  },
});
Vue.mixin(commonMixin, ListingcommonMixin);
Vue.filter('formatDate', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('MM/DD/YYYY'))
}
})
Vue.filter('formatDatee', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('YYYY-MM-DD'));
}
})
Vue.filter('formatDateTimee', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD HH:mm:ss").format(String('DD/MM/YYYY HH:mm:ss'))
}
})
Vue.filter('formatTime', function (value){
  if (value) {
    return moment(value, "HH:mm:ss").format(String('h:mm A'));
}
})
Vue.filter('formatDateTime', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD HH:mm:ss").format(String('MM/DD/YYYY h:mm A'))
}
})
Vue.filter('formatDateTimes', function (value){
  if (value) {
    var dateObject = new Date(value);
    var formattedDate = dateObject.toLocaleDateString();
    return formattedDate;
}
})
Vue.filter('currency', function (value){
  if (value) {
    var n = Number.parseFloat(value);
    if (!value || isNaN(n) || n < 0) return 0;
    return n.toFixed(2);
} else {
    return value;
}
})
const containerSelector = '#page-2-container'
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    pinia,
    i18n,
    router,
    render(h) {     
      return h(App, {
        props: {          
          props: {          
            userProfile:useloggedUserInfo().setUser(this.userProfile),
            classicEditor:useloggedUserInfo().setClassicEditor(this.classicEditor)
          } 
        } 
      });//
    },
    el: containerSelector
    
  }
});
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
// new Vue({
//   render: h => h(App),
//   pinia
// }).$mount('#app')
