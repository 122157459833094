import axios from "axios";
import { useloggedUserInfo } from "./stores/UserInfo";
// Our APIGATEWAY URL
const commonDomain = process.env.VUE_APP_COMMON_API; 
const fsmDomain = process.env.Vue_APP_FSM_API; 
const docStorageDomain = process.env.VUE_APP_DOCSTORAGE_API; 
const docmakerDomain = process.env.VUE_APP_DOCUMENTMAKER_API;
const crmDomain= process.env.Vue_APP_CRM_API
const instanceFSM = axios.create({
  baseURL:fsmDomain
});
const instanceCommon = axios.create({
    baseURL:commonDomain
  });
  const instanceDocMaker= axios.create({
    baseURL:docmakerDomain
  });
  const instanceCRM= axios.create({
    baseURL:crmDomain
  });
  const instanceDocStorage = axios.create({
    baseURL:docStorageDomain
  });
instanceFSM.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] = "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instanceCommon.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] = "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instanceDocMaker.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] = "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instanceCRM.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] = "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instanceDocStorage.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] = "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default {instanceFSM,instanceCommon,instanceDocMaker,instanceCRM,instanceDocStorage};
